<template>
	<div class="section">
		<div class="container">
			<h1>Profile</h1>
			<div v-if="loading">
				<loading icon="spinner-third" spin :pulse="false"></loading>
			</div>
			<div v-else>
				<p v-if="user && !user.Profile">
					Failed to load your profile. Please try refreshing the page.
				</p>
				<div class="columns" v-if="user.Profile">
					<div class="column is-4">
						<o-field
							label="Username:"
							:variant="v.user.UserName.$invalid && v.user.UserName.$dirty ? 'danger' : ''">
							<template v-slot:message>
								{{ v.user.UserName.$invalid && v.user.UserName.$dirty ? "Username required" : "" }}
							</template>
							<o-input
								type="text"
								v-model="user.UserName"
								@change="v.user.UserName.$touch()"></o-input>
						</o-field>
						<o-field
							label="Firstname:"
							:variant="
								v.user.Profile.FirstName.$invalid && v.user.Profile.FirstName.$dirty ? 'danger' : ''
							">
							<template v-slot:message>
								{{
									v.user.Profile.FirstName.$invalid && v.user.Profile.FirstName.$dirty
										? "First name required"
										: ""
								}}
							</template>
							<o-input
								v-model="user.Profile.FirstName"
								@change="v.user.Profile.FirstName.$touch()"></o-input>
						</o-field>
						<o-field
							label="Lastname:"
							:variant="
								v.user.Profile.LastName.$invalid && v.user.Profile.LastName.$dirty ? 'danger' : ''
							">
							<template v-slot:message>
								{{
									v.user.Profile.LastName.$invalid && v.user.Profile.LastName.$dirty
										? "Last name required"
										: ""
								}}
							</template>
							<o-input
								v-model="user.Profile.LastName"
								@change="v.user.Profile.LastName.$touch()"></o-input>
						</o-field>
						<o-field label="Company:">
							<o-input v-model="user.Profile.Company"></o-input>
						</o-field>
						<o-field
							label="Phone:"
							:variant="
								v.user.Profile.Phone.$invalid && v.user.Profile.Phone.$dirty ? 'danger' : ''
							">
							<template v-slot:message>
								{{
									v.user.Profile.Phone.$invalid && v.user.Profile.Phone.$dirty
										? "Must have a phone or fax"
										: ""
								}}
							</template>
							<phone-number
								v-model="user.Profile.Phone"
								@change="v.user.Profile.Phone.$touch()"></phone-number>
						</o-field>
						<o-field
							label="Fax:"
							:variant="v.user.Profile.Fax.$invalid && v.user.Profile.Fax.$dirty ? 'danger' : ''">
							<template v-slot:message>
								{{
									v.user.Profile.Fax.$invalid && v.user.Profile.Fax.$dirty
										? "Must have a phone or fax"
										: ""
								}}
							</template>
							<o-input v-model="user.Profile.Fax" @change="v.user.Profile.Fax.$touch()"></o-input>
						</o-field>
					</div>
					<div class="column is-4">
						<o-field label="Address:">
							<o-input v-model="user.Profile.Address"></o-input>
						</o-field>
						<o-field label="City:">
							<o-input v-model="user.Profile.City"></o-input>
						</o-field>
						<o-field label="State:">
							<o-select expanded v-model="user.Profile.StateId">
								<option v-for="s in states" v-bind:key="s.Id" :value="s.Id">
									{{ s.Name }}
								</option>
							</o-select>
						</o-field>
						<o-field label="Zipcode:">
							<o-input v-model="user.Profile.Zip"></o-input>
						</o-field>
						<br />
						<o-field>
							<o-button class="button is-primary" :disabled="v.$invalid" @click="Save">
								Save
							</o-button>
						</o-field>
					</div>
				</div>

				<div class="my-6">
					<h2>Documents</h2>
					<savedDocuments ref="docs"></savedDocuments>
					<!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
					<o-field class="file">
						<o-upload
							v-model="file"
							accept="image/png, image/jpeg, application/pdf, application/msword"
							@input="UploadFile">
							<!-- //where are our colors set up? we need to make one for primary. -->
							<o-button tag="a" variant="primary" :disabled="undefined">
								<o-icon icon="upload"></o-icon>
								<span>Upload File</span>
							</o-button>
						</o-upload>
						<span class="file-name" v-if="file">{{ file.name }}</span>
					</o-field>
				</div>

				<div class="my-6">
					<h2>Quote history</h2>
					<quote-history></quote-history>
				</div>

				<div class="my-6">
					<h2>
						Saved grilles
						<a href="/grilles" target="_blank" class="sub-header-link">
							<o-icon icon="external-link" size="small"></o-icon>
						</a>
					</h2>
					<saved-grilles></saved-grilles>
				</div>

				<div class="my-6">
					<h2>
						Saved schedules
						<a href="/schedules" target="_blank" class="sub-header-link">
							<o-icon icon="external-link" size="small"></o-icon>
						</a>
					</h2>
					<saved-schedules></saved-schedules>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
	import useValidate from "@vuelidate/core";
	import { required, email } from "@vuelidate/validators";
	import { savedDocuments, quoteHistory, savedGrilles, savedSchedules } from "@/components";
	import { PhoneNumber } from "@/components/input";
	export default {
		components: {
			savedDocuments,
			quoteHistory,
			savedGrilles,
			savedSchedules,
			PhoneNumber
		},
		props: {},
		emits: [],
		setup() {
			const global = getCurrentInstance()?.appContext.config.globalProperties;
			const $http = inject("$http");
			const $success = inject("$success");
			const $error = inject("$error");
			const loading = ref(true);
			const states = ref([]);
			const user = ref({});
			const file = ref();
			var docs = ref();

			const v = useValidate(
				{
					user: {
						UserName: {
							required,
							email
						},
						Profile: {
							FirstName: {
								required
							},
							LastName: {
								required
							},
							Phone: {
								AtleastOne() {
									return !!user.value.Profile.Phone || !!user.value.Profile.Fax;
								}
							},
							Fax: {
								AtleastOne() {
									return !!user.value.Profile.Phone || !!user.value.Profile.Fax;
								}
							}
						}
					}
				},
				{ user }
			);

			const GetStates = async () => {
				try {
					var response = await $http.get("/api/state");
					states.value = response.data;
					return response;
				} catch (err) {
					$error("Failed to get states.");
				}
			};

			const GetUser = async () => {
				try {
					var response = await $http.get("/api/user");
					user.value = response.data;
					return response;
				} catch (err) {
					$error("Failed to get user.");
				}
			};

			const Save = async () => {
				try {
					var response = await $http.post("/api/user/admin/update", user.value);
					if (response.data.User.Id) {
						$success("Successfully updated the user's info.");
					} else {
						$error("Failed to save.");
					}
					return response;
				} catch (err) {
					$error("Failed to save.");
				}
			};
			const UploadFile = async () => {
				setTimeout(async () => {
					try {
						var formData = new FormData();
						formData.append("file", file.value);
						var response = await $http.post("/api/document/upload", formData, {
							headers: { "Content-Type": "multipart/form-data" }
						});
						file.value = null;
						// console.log(response);
						// console.log("docs ", docs.value);
						// console.log("this", this.$refs.docs);

						if (response.data.Id) {
							docs.value.GetDocuments();
							$success("The file was successfully uploaded.");
						} else {
							$error("Failed to upload file.");
						}
						return response;
					} catch (err) {
						$error("Failed to upload file.");
					}
				}, 100);
			};
			///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

			onMounted(async () => {
				await GetStates();
				await GetUser();
				loading.value = false;
				//
			});

			return {
				v,
				loading,
				states,
				user,
				file,
				docs,
				GetStates,
				GetUser,
				Save,
				UploadFile
			};
		}
	};
</script>
